import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SectionTitle from "../elements/section-title";
import SpecItem from "../elements/spec-item";

const SpecSection = () => {
  const data = useStaticQuery(graphql`
    query SpecSection {
      chainImage: file(relativePath: { eq: "spec-section/chain.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      frameImage: file(relativePath: { eq: "spec-section/frame.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      gearImage: file(relativePath: { eq: "spec-section/gear.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      sizeImage: file(relativePath: { eq: "spec-section/size.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tubeImage: file(relativePath: { eq: "spec-section/tube.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      wheelImage: file(relativePath: { eq: "spec-section/wheel.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      bikeImage: file(relativePath: { eq: "spec-section/bike.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <div className="guttered-container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 d-none d-md-block tb-border-start"></div>
          <div className="col-md-3 d-none d-md-block tb-border-start"></div>
          <div className="col-6 col-md-3 tb-border-start">
            <div className="py-4 d-md-none"></div>
            <div className="py-2 d-md-none"></div>
            <div className="py-1 pb-0 d-md-none"></div>

            <div className="py-5 d-none d-md-block"></div>
            <div className="py-3 d-none d-md-block"></div>

            <SectionTitle color="#d1c5b4">SPEC</SectionTitle>
            <div className="py-4 pb-0 d-md-none"></div>

            <div className="py-4 d-none d-md-block"></div>
            <div className="py-3 d-none d-md-block"></div>
          </div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end"></div>
        </div>

        <div className="row">
          <div className="col-6 col-md-3 tb-border-start">
            <SpecItem image={data.gearImage.childImageSharp.fluid}>
              ペダルが重すぎず軽すぎないちょうど良いギア比率（フロント 44T：リア
              18T）
            </SpecItem>
          </div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0">
            <SpecItem image={data.chainImage.childImageSharp.fluid}>
              故障のリスクが少ないシングルギアと錆びにくいチェーン
            </SpecItem>
          </div>
          <div className="col-6 col-md-3 tb-border-start">
            <SpecItem image={data.wheelImage.childImageSharp.fluid}>
              走りの軽快さを実現する26インチタイヤ（26 x 1.15HE）
            </SpecItem>
          </div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end">
            <SpecItem image={data.tubeImage.childImageSharp.fluid}>
              街の自転車屋さんでも空気入れが可能な英式バルブ
            </SpecItem>
          </div>
          <div className="col-6 col-md-3 tb-border-start">
            <SpecItem image={data.frameImage.childImageSharp.fluid}>
              振動吸収性に優れた、細身のクロモリフレーム
            </SpecItem>
          </div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0">
            <SpecItem image={data.sizeImage.childImageSharp.fluid}>
              145cm〜まで対応できるS・Mの2サイズ展開
            </SpecItem>
          </div>
          <div className="col-6 col-md-3 tb-border-start">
            <SpecItem image={data.bikeImage.childImageSharp.fluid}>
              センタースタンド付き
            </SpecItem>
          </div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end"></div>
        </div>
      </div>
    </div>
  );
};

export default SpecSection;
