import * as React from "react";
import { Link } from "gatsby";
import logo from "../../images/misc/logo.svg";
import FeaturedNavLink from "../elements/featured-nav-link";
import ScrollIcon from "../elements/scroll-icon";

const IntroSection = () => {
  return (
    <div className="guttered-container overflow-x-hidden">
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 tb-border-start intro-logo-column">
            <div className="logo-wrap">
              <Link to="https://tokyobike.com">
                <img src={logo} height="909" width="4637" alt="Tokyobike" />
              </Link>
            </div>

            <div className="scroll-down-button-wrap d-none d-md-block">
              <a href="#concept" className="scroll-to-next">
                <span className="scroll-text">Scroll Down</span>
                <ScrollIcon styleName="scroll-down" />
              </a>
            </div>

            <FeaturedNavLink hash="concept" title="Concept" color="#e5b2a1">
              CONCEPT
            </FeaturedNavLink>
          </div>
          <div className="col-3 tb-border-start-0 tb-border-start-md">
            <FeaturedNavLink hash="design" title="Design" color="#92c7bc">
              DESIGN
            </FeaturedNavLink>
          </div>
          <div className="col-3 tb-border-start">
            <FeaturedNavLink hash="spec" title="Spec" color="#d1c5b4">
              SPEC
            </FeaturedNavLink>
          </div>
          <div className="col-3 tb-border-start-0 tb-border-start-md tb-border-end position-relative">
            <div
              className="hero-text-wrap js-inview d-none d-md-flex"
              id="hero-text"
            >
              <div className="hero-text-inner-wrap">
                <p className="tokyobike-text">TOKYOBIKE</p>
                <p className="leger-text">LEGER</p>
              </div>
            </div>
            <FeaturedNavLink hash="color" title="Color" color="#414f2c">
              COLOR
            </FeaturedNavLink>
            <FeaturedNavLink
              hash="journal"
              title="Journal"
              halign="right"
              color="#48637e"
            >
              JOURNAL
            </FeaturedNavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroSection;
