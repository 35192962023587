import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

const ConceptSwiper = () => {
    const data = useStaticQuery(graphql`
        query ConceptSwiperImages {
            desktopImages: allFile(
                filter: { relativePath: { regex: "/concept-slides/" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(height: 630) {
                            src
                            width
                            height
                            originalName
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
            }
            mobileImages: allFile(
                filter: { relativePath: { regex: "/concept-slides/" } }
            ) {
                nodes {
                    childImageSharp {
                        fixed(height: 310) {
                            src
                            width
                            height
                            originalName
                            ...GatsbyImageSharpFixed_noBase64
                        }
                    }
                }
            }
        }
    `);
    let images = data.desktopImages;
    // let height = 630;
    if (typeof window !== `undefined`) {
        images =
            window.innerWidth >= 768 ? data.desktopImages : data.mobileImages;
        // height = window.innerWidth >= 768 ? 630 : 310;
    }

    const swiperParams = {
        // cssMode: true,
        // preloadImages: false,
        // lazy: true,
        speed: 800,
        loop: true,
        roundLengths: true,
        freeMode: true,
        slidesPerView: "auto",
        spaceBetween: 10,
        autoplay: {
            delay: 2000,
            disableOnInteraction: true,
        },
    };

    return (
        <div className="container-fluid tb-border-top tb-border-bottom">
            <div className="row">
                <div className="col-12 vertical-gutter">
                    <div className="concept-swiper-container js-reveal">
                        <Swiper {...swiperParams}>
                            {images.nodes.map((o, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <div className="slide-image-wrapper">
                                            <img
                                                // className="swiper-lazy"
                                                src={
                                                    o.childImageSharp.fixed.src
                                                }
                                                height={
                                                    o.childImageSharp.fixed
                                                        .height
                                                }
                                                width={
                                                    o.childImageSharp.fixed
                                                        .width
                                                }
                                                alt={
                                                    o.childImageSharp.fixed
                                                        .originalName
                                                }
                                            />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConceptSwiper;
