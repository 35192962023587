import * as React from "react";
import AppContext from "../../context/AppContext";

const MenuDrawer = () => {
    return (
        <AppContext.Consumer>
            {(appState) => {
                return (
                    <div
                        className={`menu-drawer-container ${
                            appState.isMenuOpen ? "is-open" : ""
                        }`}
                    >
                        <div className="menu-drawer-wrap">
                            <div className="menu-actions">
                                <button
                                    className="close-menu-button"
                                    onClick={appState.toggleMenu}
                                >
                                    CLOSE MENU
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                            <div className="menu-content">
                                <ul className="menu-items-list">
                                    <li>
                                        <a href="#concept">CONCEPT</a>
                                    </li>
                                    <li>
                                        <a href="#design">DESIGN</a>
                                    </li>
                                    <li>
                                        <a href="#spec">SPEC</a>
                                    </li>
                                    <li>
                                        <a href="#color">COLOR</a>
                                    </li>
                                    <li>
                                        <a href="#journal">JOURNAL</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            }}
        </AppContext.Consumer>
    );
};

export default MenuDrawer;
