import * as React from "react";
import { Helmet } from "react-helmet";
import IntroSwiper from "../components/swipers/intro-swiper";
import ConceptSection from "../components/sections/concept-section";
import DesignSection from "../components/sections/design-section";
import SpecSection from "../components/sections/spec-section";
import ColorSection from "../components/sections/color-section";
import JournalSection from "../components/sections/journal-section";

import ConceptSwiper from "../components/swipers/concept-swiper";
import IntroSection from "../components/sections/intro-section";
import FooterSection from "../components/sections/footer-section";
import MenuDrawer from "../components/sections/menu-drawer";
import ToggleMenuButton from "../components/elements/toggle-menu-button";
// markup
import { useGsapReveal } from "../components/animation/useGsapReveal";

import favicon from "../images/favicon.png";

const IndexPage = () => {
    useGsapReveal();
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <title>TOKYOBIKE LEGER 特設サイト</title>
                <link rel="canonical" href="https://tokyobike.com" />
                <meta
                    name="description"
                    content="気持ちのいい道具“ってなんだろう。
                    当たり前のようにそばにあって、気がねなく使えるもの。
                    シンプルだけど、さりげない心づかいを感じるもの。
                    いろいろ試してみたけれど、ふたたび手にとってしまうもの
                    TOKYOBIKE LEGER(レジェ)は、そんな気持ちのよさを形にしました。"
                />
                <link rel="icon" href={favicon} />
            </Helmet>
            <MenuDrawer />
            <section className="section" id="intro">
                <ToggleMenuButton styleName="d-md-none" />
                <IntroSwiper />
                <IntroSection />
            </section>
            <section className="section stick-toggle-menu" id="concept">
                <ToggleMenuButton styleName="sticky-button d-none d-md-block" />
                <ConceptSection />
            </section>
            <section className="section" id="concept-swiper">
                <ConceptSwiper />
                <div className="guttered-container d-none d-md-block">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6 col-md-3 tb-border-start">
                                <div className="py-5"></div>
                            </div>
                            <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0"></div>
                            <div className="col-6 d-none d-md-block tb-border-start tb-border-end"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="design">
                <DesignSection />
            </section>
            <section className="section" id="spec">
                <SpecSection />
            </section>
            <section className="section" id="color">
                <ColorSection />
            </section>
            <section className="section" id="journal">
                <JournalSection />
            </section>
            <section className="section" id="footer">
                <FooterSection />
            </section>
        </main>
    );
};

export default IndexPage;
