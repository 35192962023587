import * as React from "react";

const ReadmoreLink = (props) => {
    return (
        <div className={`readmore-link-wrap ${props.styleName || ""}`}>
            <p>
                <a
                    href={props.linkURL || ""}
                    className="readmore-link"
                    target={props.target || "_self"}
                    tilte={ props.title || ""}
                >
                    <span
                        className="bullet"
                        style={
                            props.color ? { backgroundColor: props.color } : {}
                        }
                    ></span>
                    <span
                        className="title"
                        style={props.color ? { color: props.color } : {}}
                    >
                        {props.children || "READ MORE"}
                    </span>
                </a>
            </p>
        </div>
    );
};

export default ReadmoreLink;
