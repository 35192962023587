import * as React from "react";

const FooterLinkButton = (props) => {
    return (
        <div className="footer-link-button">
            <a href={props.linkURL} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        </div>
    );
};

export default FooterLinkButton;
