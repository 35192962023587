import * as React from "react";
import Img from "gatsby-image";
const JournalPost = (props) => {
    if (props.linkURL) {
        return (
            <div className="journal-post-wrap js-reveal">
                <a href={`${props.linkURL}`} target="_blank" rel="noreferrer">
                    <div className="journal-image-wrap">
                        <Img fluid={props.image} />
                    </div>
                    <div className="description-container">
                        {props.children}
                    </div>
                </a>
            </div>
        );
    } else {
        return (
            <div className="journal-post-wrap js-reveal">
                <div className="journal-image-wrap">
                    <Img fluid={props.image} />
                </div>
                <div className="description-container">{props.children}</div>
            </div>
        );
    }
};

export default JournalPost;
