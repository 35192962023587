import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SectionTitle from "../elements/section-title";
import JournalPost from "../elements/journal-post";
const JournalSection = () => {
  const data = useStaticQuery(graphql`
    query JournalSectionImages {
      journal1: file(relativePath: { eq: "journal-section/journal-1.jpg" }) {
        childImageSharp {
          fluid(srcSetBreakpoints: [600], sizes: "600px") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      journal2: file(relativePath: { eq: "journal-section/journal-2.jpg" }) {
        childImageSharp {
          fluid(srcSetBreakpoints: [600], sizes: "600px") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      journal3: file(
        relativePath: { eq: "journal-section/journal-5-new.jpg" }
      ) {
        childImageSharp {
          fluid(srcSetBreakpoints: [600], sizes: "600px") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      journal6: file(relativePath: { eq: "journal-section/journal-6.jpg" }) {
        childImageSharp {
          fluid(srcSetBreakpoints: [600], sizes: "600px") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      journal7: file(relativePath: { eq: "journal-section/journal-7.jpg" }) {
        childImageSharp {
          fluid(srcSetBreakpoints: [600], sizes: "600px") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <div className="guttered-container tb-border-bottom">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 col-md-3 tb-border-start">
            <div className="py-5 d-none d-md-block"></div>
            {/* <div className="py-3 d-md-none"></div> */}
            <div className="py-3"></div>
          </div>
          <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
          <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end"></div>
        </div>

        <div className="row d-md-none">
          <div className="col-6 tb-border-start">
            <SectionTitle color="#48637e">JOURNAL</SectionTitle>
            <div className="py-4 pb-0 d-md-none"></div>
            <div className="py-1 pb-0 d-md-none"></div>

            <div className="py-3 d-none d-md-block"></div>
            <div className="py-3 d-none d-md-block"></div>
            <div className="py-2 d-none d-md-block"></div>
          </div>
          <div className="col-6 tb-border-start tb-border-end"></div>
        </div>

        <div className="row">
          <div className="col-6 col-md-3 tb-border-start">
            <JournalPost
              image={data.journal2.childImageSharp.fluid}
              linkURL="https://tokyobike.com/journal/product-tokyobike-leger/"
            >
              <p>TOKYOBIKE LEGER(レジェ）と楽しむひとり時間</p>
            </JournalPost>
          </div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0">
            <JournalPost
              image={data.journal1.childImageSharp.fluid}
              linkURL="https://tokyobike.com/journal/product-mono-leger-dayoff/"
            >
              <p>なんでもない休日が、特別に。TOKYOBIKE MONOとLEGER。</p>
            </JournalPost>
          </div>
          <div className="col-6 col-md-3 tb-border-start">
            <JournalPost
              image={data.journal3.childImageSharp.fluid}
              linkURL="https://tokyobike.com/journal/summer-breeze/"
            >
              <p>Summer Breeze with TOKYOBIKE LEGER</p>
            </JournalPost>
          </div>
          <div className="d-none d-md-block col-6 col-md-3 tb-border-start tb-border-end">
            <SectionTitle align="right" color="#48637e">
              JOURNAL
            </SectionTitle>
          </div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0">
            <JournalPost
              image={data.journal6.childImageSharp.fluid}
              linkURL="https://tokyobike.com/journal/tokyobike-archive-55/"
            >
              <p>TOKYOBIKE LEGER ブルーグレー カスタマイズ</p>
            </JournalPost>
          </div>
          <div className="col-6 col-md-3 tb-border-start">
            <JournalPost
              image={data.journal7.childImageSharp.fluid}
              linkURL="https://tokyobike.com/journal/tokyobike-archive-12/"
            >
              <p>TOKYOBIKE LEGER アイボリー カスタマイズ</p>
            </JournalPost>
          </div>
          <div className="d-none d-md-block col-6 col-md-3 tb-border-start"></div>
          <div className="d-md-block col-6 col-md-3 tb-border-start tb-border-end"></div>
        </div>

        {/* Spacer Row */}
        <div className="row">
          <div className="col-6 col-md-3 tb-border-start">
            <div className="py-3 pb-0 d-md-none"></div>
            <div className="py-1 pb-0 d-md-none"></div>

            <div className="py-5 d-none d-md-block"></div>
            <div className="py-5 d-none d-md-block"></div>
          </div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0"></div>
          <div className="col-6 col-md-3 tb-border-start"></div>
          <div className="col-6 col-md-3 tb-border-start tb-border-end"></div>
        </div>
      </div>
    </div>
  );
};

export default JournalSection;
