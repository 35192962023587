import * as React from "react";
import FooterLinkButton from "../elements/footer-link-button";
import ScrollIcon from "../elements/scroll-icon";

const FooterSection = () => {
    return (
        <div className="guttered-container">
            <div className="container-fluid">
                {/* Spacer Row */}
                <div className="row">
                    <div className="col-12">
                        <div className="py-4"></div>
                        <div className="py-4 pb-0 d-md-none"></div>
                        <div className="py-1 pb-0 d-md-none"></div>
                    </div>
                </div>

                <div className="row justify-content-md-center">
                    <div className="col-6 col-md-3">
                        <FooterLinkButton linkURL="https://mono.tokyobike.com/">
                            TOKYOBIKE MONO
                            <br />
                            スペシャルサイト
                        </FooterLinkButton>
                    </div>
                    <div className="col-6 col-md-3">
                        <FooterLinkButton linkURL="https://tokyobike.com/stockist/">
                            全国のお取り扱い店舗
                        </FooterLinkButton>
                    </div>
                    <div className="col-6 col-md-3">
                        <FooterLinkButton linkURL="https://tokyobike.com/product/tokyobike-leger/">
                            Online Store
                        </FooterLinkButton>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 text-center">
                        <a href="#intro" className="scroll-to-top">
                            <ScrollIcon styleName="footer-scroll-icon" />

                            <span className="scroll-text">Back to top</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterSection;
