import * as React from "react";
import FeaturedNavLink from "../elements/featured-nav-link";
import ReadmoreLink from "../elements/readmore-link";
import SectionTitle from "../elements/section-title";

const ConceptSection = () => {
    return (
        <div className="guttered-container">
            {/* Nav links row */}
            <div className="container-fluid position-relative">
                <div className="row">
                    <div className="col-3">
                        <FeaturedNavLink
                            hash="concept"
                            title="Concept"
                            color="#e5b2a1"
                            valign="top"
                        >
                            CONCEPT
                        </FeaturedNavLink>
                    </div>
                    <div className="col-3">
                        <FeaturedNavLink
                            hash="design"
                            title="Design"
                            color="#92c7bc"
                            valign="top"
                        >
                            DESIGN
                        </FeaturedNavLink>
                    </div>
                    <div className="col-3">
                        <FeaturedNavLink
                            hash="spec"
                            title="Spec"
                            color="#d1c5b4"
                            valign="top"
                        >
                            SPEC
                        </FeaturedNavLink>
                    </div>
                    <div className="col-3">
                        <FeaturedNavLink
                            hash="color"
                            title="Color"
                            color="#414f2c"
                            valign="top"
                        >
                            COLOR
                        </FeaturedNavLink>
                        <FeaturedNavLink
                            hash="journal"
                            title="Journal"
                            halign="right"
                            color="#48637e"
                            valign="top"
                        >
                            JOURNAL
                        </FeaturedNavLink>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                {/* Row 1 */}
                <div className="row">
                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="py-3"></div>
                        <div className="py-5 d-none d-md-block"></div>
                        <div id="hero-text-2" className="js-inview">
                            <p className="d-md-none tokyobike-text">
                                TOKYOBIKE
                            </p>
                            <p className="d-md-none leger-text">LEGER</p>
                        </div>
                        <div className="py-4"></div>
                        <div className="py-1"></div>
                        <div className="py-5 pb-0 d-none d-md-block"></div>
                        <div className="py-3 pb-0 d-none d-md-block"></div>
                    </div>
                    <div className="col-6 col-md-6 tb-border-start-md tb-border-end tb-border-end-md-0"></div>
                    <div className="col-md-3 d-none d-md-block tb-border-start tb-border-end"></div>
                </div>

                {/* Row 2 */}

                <div className="row">
                    <div className="col-12 col-md-3 tb-border-start tb-border-end tb-border-end-md-0">
                        <SectionTitle color="#e5b2a1">CONCEPT</SectionTitle>
                        <div className="py-2 d-md-none"></div>
                        <div className="py-1 d-md-none"></div>
                    </div>
                    <div className="col-12 col-md-6 tb-border-start tb-border-end tb-border-end-md-0">
                        <div className="description-container tb-font-notosans-light js-reveal">
                            <h4 className="description-heading tb-font-notosans-light">
                                “気持ちのいい道具“ってなんだろう。
                            </h4>
                            <p>
                                当たり前のようにそばにあって、気がねなく使えるもの。
                                <br />
                                シンプルだけど、さりげない心づかいを感じるもの。
                                <br />
                                いろいろ試してみたけれど、ふたたび手にとってしまうもの。
                            </p>
                            <p>
                                TOKYOBIKE
                                LEGER(レジェ)は、そんな気持ちのよさを形にしました。
                            </p>
                            <p>
                                速く走るのではなく、軽やかに走る。
                                <br />
                                街で目を引く、凛とした佇まい。
                                <br />
                                誰もがリラックスできる、ゆったりとした乗り心地。
                            </p>
                            <p>
                                肩の力を抜いて、流れる景色に身を任せれば
                                <br />
                                いつもより少し先へと、進みたくなる自転車です。
                            </p>
                        </div>
                        <div className="py-2"></div>
                        <div className="py-2 d-md-none"></div>
                        <div className="py-5 d-none d-md-block"></div>
                        <div className="py-1 pb-0 d-none d-md-block"></div>

                        <ReadmoreLink
                            linkURL="https://tokyobike.com"
                            color="#e5b2a1"
                            styleName="js-reveal"
                            target="_blank"
                        >
                            BRAND SITE
                        </ReadmoreLink>

                        <div className="py-3"></div>
                        <div className="py-5 d-none d-md-block"></div>
                        <div className="py-5 d-none d-md-block"></div>
                    </div>
                    <div className="col-12 col-md-3 tb-border-start tb-border-end"></div>
                </div>
            </div>
        </div>
    );
};

export default ConceptSection;
