import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Pagination, Autoplay } from "swiper";

SwiperCore.use([EffectFade, Pagination, Autoplay]);

const ColorSwiper = (props) => {
    const data = useStaticQuery(graphql`
        query ColorSwiperImages {
            allFile(
                filter: { relativePath: { regex: "/color-section/" } }
                sort: { fields: name }
            ) {
                nodes {
                    childImageSharp {
                        fluid(
                            srcSetBreakpoints: [1200]
                            sizes: "1200px"
                            quality: 100
                        ) {
                            originalName
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
    `);

    const swiperParams = {
        effect: "fade",
        pagination: {
            clickable: true,
            el: ".color-swiper-pagination",
            type: "bullets",
        },
        autoplay: {
            delay: 3000,
            disableOnInteraction: true,
        },
        onImagesReady: () => {
            // Workaround to trigger update
            setTimeout(function () {
                window.dispatchEvent(new Event("resize")); // Resets the swiper
            }, 2000);
        },
        onSlideChange: (swiper) => {
            props.slideChangeHandler(swiper.activeIndex);
        },
    };

    return (
        <div className="color-swiper-container">
            <Swiper {...swiperParams}>
                {data.allFile.nodes.map((o, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <div className="slide-image-wrapper">
                                <Img fluid={o.childImageSharp.fluid} />
                            </div>
                        </SwiperSlide>
                    );
                })}
                <div slot="container-end" className="slot-container-end">
                    <div className="color-swiper-pagination"></div>
                </div>
            </Swiper>
        </div>
    );
};

export default ColorSwiper;
