import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from "swiper";
// import "swiper/swiper.scss";
// import "swiper/components/effect-fade/effect-fade.scss";
SwiperCore.use([EffectFade, Autoplay]);

const { useState } = React;
const IntroSwiper = () => {
    const data = useStaticQuery(graphql`
        query IntroSwiperImages {
            allFile(
                filter: { relativePath: { regex: "/intro-slides/" } }
                sort: { fields: name }
            ) {
                nodes {
                    childImageSharp {
                        fluid(sizes: "110vw") {
                            originalName
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
    `);

    let allCaptions = [
        "BLUE JADE",
        "IVORY",
        "BLUE JADE",
        "BLACK <span style='font-size: 60%;'>(終売カラー)</span>",
        "BEIGE RED",
        "BLUE GRAY",
        "MOSS GREEN",
    ];
    const [state, setState] = useState({
        slideCaption: "BLUE JADE",
        activeIndex: 1,
        totalSlides: 7,
    });

    const leftPad = (number, targetLength) => {
        var output = number + "";
        while (output.length < targetLength) {
            output = "0" + output;
        }
        return output;
    };

    const swiperParams = {
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        speed: 1000,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        onSlideChange: (swiper) => {
            setState({
                ...state,
                slideCaption: allCaptions[swiper.activeIndex],
                activeIndex: swiper.activeIndex + 1,
            });
        },
    };
    return (
        <div className="intro-swiper-container">
            <Swiper {...swiperParams}>
                {data.allFile.nodes.map((o, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <div className="slide-image-wrap">
                                <Img fluid={o.childImageSharp.fluid} />
                            </div>
                        </SwiperSlide>
                    );
                })}
                <div slot="container-start" className="container-start">
                    <div className="guttered-container">
                        <div className="container-fluid">
                            <div className="row tb-border-bottom tb-border-bottom-md-0">
                                <div className="col-6 col-sm-6 col-md-3 tb-border-start"></div>
                                <div className="col-md-3 d-none d-md-block tb-border-start"></div>
                                <div className="col-3 tb-border-start tb-border-bottom-md">
                                    <div className="slide-numbers text-right vertical-gutter">
                                        {leftPad(state.activeIndex, 2)}/
                                        {leftPad(state.totalSlides, 2)}
                                    </div>
                                </div>
                                <div className="col-3 tb-border-start tb-border-bottom-md tb-border-end">
                                    <div className="slide-caption text-right vertical-gutter" dangerouslySetInnerHTML={{__html: state.slideCaption}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Swiper>
        </div>
    );
};
export default IntroSwiper;
