import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import SectionTitle from "../elements/section-title";
import ReadmoreLink from "../elements/readmore-link";

const DesignSection = (props) => {
    const data = useStaticQuery(graphql`
        query DesignSectionImage {
            file(relativePath: { eq: "design-section/design-image.jpg" }) {
                id
                childImageSharp {
                    fluid(
                        srcSetBreakpoints: [800, 1200]
                        sizes: "(min-width: 768px) 1200px, 800px"
                    ) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <div className="guttered-container">
            <div className="container-fluid">
                {/* Spacer row */}
                <div className="row">
                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="py-4"></div>
                        <div className="py-2 d-md-none"></div>
                        <div className="py-1 pb-0 d-md-none"></div>
                        <div className="py-5 d-none d-md-block"></div>
                        <div className="py-1 d-none d-md-block"></div>
                    </div>
                    <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0"></div>
                    <div className="col-6 d-none d-md-block tb-border-start tb-border-end"></div>
                </div>

                {/* Content Row */}
                <div className="row">
                    {/* Big Device */}
                    <div className="col-md-3 d-md-block d-none tb-border-start"></div>

                    {/* Both Devices */}
                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="flex-content-box">
                            <SectionTitle color="#92c7bc">DESIGN</SectionTitle>

                            <div className="description-container js-reveal">
                                <div className="py-4 pb-0 d-md-none"></div>
                                <div className="py-3 d-none d-md-block"></div>
                                <div className="py-3 d-none d-md-block"></div>

                                <p className="tb-font-notosans-light">
                                    ゆったりと気持ちの良い乗り
                                    心地を実現するTOKYOBIKE
                                    LEGERのデザインをご紹介し ます。
                                </p>

                                <div className="py-3 d-md-none"></div>
                                <div className="py-1 pb-0 d-md-none"></div>

                                <div className="py-4 d-none d-md-block"></div>
                                <div className="py-2 d-none d-md-block"></div>
                                <div className="py-1 d-none d-md-block"></div>
                            </div>

                            <ReadmoreLink
                                styleName="flex-item__aligned-end d-none d-md-block js-reveal"
                                linkURL="/design"
                                color="#92c7bc"
                                title="Read more"
                            >
                                READ MORE <span class="screen-reader-text">DETAILS</span>
                            </ReadmoreLink>

                            <div className="py-4 pb-0 d-md-none"></div>
                            <div className="py-1 pb-0 d-md-none"></div>
                        </div>
                    </div>

                    {/* Small Device */}
                    <div className="col-6 d-md-none tb-border-start tb-border-end">
                        <SectionTitle styleName="opacity-0" color="#92c7bc">
                            DESIGN
                        </SectionTitle>

                        <div className="description-container js-reveal">
                            <div className="py-4 pb-0 d-md-none"></div>
                            <div className="py-3 d-none d-md-block"></div>
                            <div className="py-3 d-none d-md-block"></div>

                            <p className="tb-font-notosans-light opacity-0">
                                ゆったりと気持ちの良い乗り
                                心地を実現するTOKYOBIKE
                                LEGERのデザインをご紹介し ます。
                            </p>

                            <div className="py-3 d-md-none"></div>
                            <div className="py-1 pb-0 d-md-none"></div>

                            <div className="py-4 d-none d-md-block"></div>
                            <div className="py-2 d-none d-md-block"></div>
                            <div className="py-1 d-none d-md-block"></div>
                        </div>

                        <ReadmoreLink
                            linkURL="/design"
                            color="#92c7bc"
                            styleName="js-reveal"
                            title="Read more"
                        >
                            READ MORE <span class="screen-reader-text">DETAILS</span>
                        </ReadmoreLink>

                        <div className="py-4 pb-0 d-md-none"></div>
                        <div className="py-1 pb-0 d-md-none"></div>
                    </div>

                    {/* Both Devices */}
                    <div className="col-12 col-md-6 tb-border-start tb-border-top tb-border-end tb-border-bottom">
                        <div className="image-wrapper column-height">
                            <Img
                                className="js-reveal-image"
                                fluid={data.file.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                </div>

                {/* Spacer row */}
                <div className="row d-none d-md-flex">
                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="py-5 d-none d-md-block"></div>
                        <div className="py-2 d-none d-md-block"></div>
                        <div className="py-1 d-none d-md-block"></div>
                    </div>
                    <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-6 col-md-3 tb-border-start tb-border-end"></div>
                </div>
            </div>
        </div>
    );
};

export default DesignSection;
