import * as React from "react";
import SectionTitle from "../elements/section-title";
import ColorSwiper from "../swipers/color-swiper";

const { useState } = React;
const ColorSection = () => {
    let allCaptions = [
        "BEIGE RED",
        "BLACK <span style='font-size: 60%;'>(終売カラー)</span>",
        "BLUE GRAY",
        "BLUE JADE",
        "IVORY",
        "MOSS GREEN",
        "COAL GRAY"
    ];
    let allColors = [
        "#e5b2a1",
        "#000000",
        "#48637e",
        "#92c7bc",
        "#d1c5b4",
        "#414f2c",
        "#3F454C",
    ];

    const [state, setState] = useState({
        slideCaption: "BEIGE RED",
        slideColor: "#e5b2a1",
        activeIndex: 1,
    });
    const setActiveSlideIndex = (newIndex) => {
        let newSlideCaption = allCaptions[newIndex];
        let newSlideColor = allColors[newIndex];
        setState({
            slideCaption: newSlideCaption,
            slideColor: newSlideColor,
            activeIndex: newIndex,
        });
    };

    return (
        <div className="guttered-container">
            <div className="container-fluid">
                {/* Spacer row */}
                <div className="row">
                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="py-2"></div>
                        <div className="py-3 d-none d-md-block"></div>
                        <div className="py-5 d-none d-md-block"></div>
                    </div>
                    <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0"></div>
                    <div className="col-6 col-md-3 tb-border-start"></div>
                    <div className="col-6 col-md-3 tb-border-start tb-border-end"></div>
                </div>

                <div className="row d-md-none">
                    <div className="col-6 tb-border-start">
                        <SectionTitle color="#414f2c">COLOR</SectionTitle>
                        <div className="py-4 pb-0"></div>
                        <div className="py-1 pb-0"></div>
                    </div>
                    <div className="col-6 tb-border-start tb-border-end"></div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6 tb-border-start tb-border-end tb-border-end-md-0 tb-border-top tb-border-bottom">
                        <ColorSwiper slideChangeHandler={setActiveSlideIndex} />
                    </div>
                    <div className="col-md-3 d-none d-md-block tb-border-start">
                        <div className="flex-content-box">
                            <SectionTitle color="#414f2c" align="right">
                                COLOR
                            </SectionTitle>
                            <div className="flex-item__aligned-end">
                                <p
                                    className="color-slide-caption js-reveal"
                                    style={{ color: state.slideColor }}
                                    dangerouslySetInnerHTML={{__html: state.slideCaption}}
                                ></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 d-none d-md-block tb-border-start tb-border-end"></div>
                </div>
                <div className="row d-md-none">
                    <div className="col-6 tb-border-start"></div>
                    <div className="col-6 tb-border-start tb-border-end vertical-gutter">
                        <p
                            className="color-slide-caption text-end js-reveal"
                            style={{ color: state.slideColor }}
                            dangerouslySetInnerHTML={{__html: state.slideCaption}}
                        ></p>
                    </div>
                </div>

                {/* Spacer row */}
                <div className="row">
                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="py-5 d-none d-md-block"></div>
                        <div className="py-3 d-none d-md-block"></div>
                    </div>
                    <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-6 col-md-3 tb-border-start tb-border-end"></div>
                </div>
            </div>
        </div>
    );
};

export default ColorSection;
