import * as React from "react";
import Img from "gatsby-image";

const SpecItem = (props) => {
    return (
        <div className="spec-item-wrap js-reveal">
            <div className="spec-icon-wrap">
                <Img
                    fluid={props.image}
                    imgStyle={{
                        objectFit: "contain",
                    }}
                />
            </div>
            <div className="description-container">
                <p className="tb-font-notosans-light">{props.children}</p>
            </div>
        </div>
    );
};

export default SpecItem;
